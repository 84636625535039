import { default as indexSGx7l4vewJMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom/index.vue?macro=true";
import { default as packagesQnVdU8j3HyMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom/packages.vue?macro=true";
import { default as partsSSujDMUz8bMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom/parts.vue?macro=true";
import { default as customJ76ZzDESPRMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom.vue?macro=true";
import { default as indexVlDTWAvbMrMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/index.vue?macro=true";
import { default as ratesEUMf8mIiaqMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/rates.vue?macro=true";
import { default as subscriptionp4NTib0syhMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/subscription.vue?macro=true";
import { default as accountySfKCMwrcgMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account.vue?macro=true";
import { default as _91id_93wElKsGES2UMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexvvnIEpGHpwMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93FPHtzhmO52Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93txCmUydJmOMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as indexB6vqAdm0goMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehicules1XBZaUVgueMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_9390Q4LTvAL5Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/Boutique/[title]-[id].vue?macro=true";
import { default as indexxtJR96IZinMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/Boutique/index.vue?macro=true";
import { default as productsGyGaIprBzVMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/Boutique/products.vue?macro=true";
import { default as indexxp7KhAF1x2Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/index.vue?macro=true";
import { default as configuration7i8c8qYkShMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as index7cm754tclFMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planKCfNARKQ08Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksZXiGSbrcJ7Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenancextJZkrXkcYMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manuals3QXSOXv1NKMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/manuals.vue?macro=true";
import { default as timesoOolhgTr5NMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/times.vue?macro=true";
import { default as catalogq4dTSY7vCgMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog.vue?macro=true";
import { default as commandeqDBNrqtTtYMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/commande.vue?macro=true";
import { default as _91tab_93YqCOGFOdZ0Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93YgC5Q2UcpfMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93FawFGLVDkMMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfEIhp3LEWDaMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexmVXH5wYBtvMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/index.vue?macro=true";
import { default as login5PcIl4aCUHMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/login.vue?macro=true";
import { default as panierNvkIff40I0Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/panier.vue?macro=true";
import { default as index5dcNH4l4k7Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/pneumatique/index.vue?macro=true";
import { default as productsksmgt65nYgMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiqueu6VO3ANNcZMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/pneumatique.vue?macro=true";
import { default as maintenance9d7gFiWkHzMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/print/maintenance.vue?macro=true";
import { default as productsDwxpr7c49oMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/products.vue?macro=true";
import { default as signupqxrkEu6M7XMeta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45borditD5zbdE07Meta } from "/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountySfKCMwrcgMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account.vue"),
    children: [
  {
    name: customJ76ZzDESPRMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/activite/vehicules.vue")
  },
  {
    name: "Boutique-title-id",
    path: "/Boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/Boutique/[title]-[id].vue")
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/Boutique/index.vue")
  },
  {
    name: "Boutique-products",
    path: "/Boutique/products",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/Boutique/products.vue")
  },
  {
    name: catalogq4dTSY7vCgMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/index.vue")
  },
  {
    name: maintenancextJZkrXkcYMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfEIhp3LEWDaMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login5PcIl4aCUHMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/panier.vue")
  },
  {
    name: pneumatiqueu6VO3ANNcZMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenance9d7gFiWkHzMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupqxrkEu6M7XMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/equipautopro.fr/app.equipautopro.fr/pages/tableau-de-bord.vue")
  }
]